html, body {
  min-height: 100%;
  height: 100%;
}

#viewport > * > md-content {
  background: transparent !important;
}

#sidebar {
  background: #222;
  color: white;
  width: 360px;
}

#sidebar .logo-area {
  padding-left: 20px;
  padding-top: 20px;
}

#sidebar .logo-area img {
  max-height: 80px;
}

#sidebar md-content {
  background: transparent !important;
}

#sidebar section {
  padding-left: 25px;
  margin-top: 50px;
  margin-bottom: 10px;
  color: #ccc;
  opacity: 0.5;
}

#sidebar .menu-item {
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}

#sidebar .menu-item.active {
  background-color: #758f10;
}

#sidebar .menu-item[disabled] {
  opacity: 0.4;
}

#sidebar .menu-item md-icon {
  width: auto;
  height: auto;
  font-size: 40px;
  color: #ccc;
  margin: 0 17px 0 3px;
}

#sidebar .menu-item span {
  text-transform: uppercase;
  color: #ccc;
}

#sidebar .menu-item .user-time span {
  text-transform: none !important;
  font-weight: bold;
}

#sidebar team-working-status .menu-item {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

#sidebar team-working-status .menu-item .avatar-status {
  margin-left: 0;
}

#sidebar .footer {
  background-color: black;
  margin: 0;
  padding: 15px 10px;
}

#sidebar .footer .avatar {
  width: auto;
  height: auto;
  max-width: 50px;
  max-height: 50px;
}

#sidebar .footer md-icon {
  color: #ccc;
}

execution-preview {
  border-bottom: solid 1px #ececec;
}

execution-preview task-preview, execution-preview task-headline {
  border: 0;
}

execution-preview mdp-time-picker .md-ink-ripple {
  display: none;
}

execution-preview mdp-time-picker md-input-container {
  margin: 0;
  padding-left: 20px;
}

execution-preview mdp-time-picker md-input-container input {
  border: 0 !important;
}

execution-preview mdp-time-picker md-input-container .md-errors-spacer {
  display: none;
}

#execution-day-menu-content md-menu-item {
  height: auto !important;
  max-height: none !important;
}

#execution-day-menu-content md-menu-item .md-ink-ripple {
  margin: 0;
}

#execution-day-menu-content md-menu-item md-input-container {
  margin: 0;
}

#execution-day-menu-content md-menu-item md-input-container .md-errors-spacer {
  display: none;
}

execution-preview .open-new-window .md-button {
  visibility: hidden;
}

execution-preview .open-new-window .md-button md-icon {
  margin-right: 0;
}

execution-preview .open-new-window .md-button:hover {
  opacity: 1;
}

execution-preview:hover .md-button {
  visibility: visible;
}

clock-bar {
  min-height: 80px;
  padding: 0 10px 0 20px;
}

clock-bar task-preview, clock-bar task-headline {
  border-bottom: 0;
  width: 100%;
}

.filters-bar {
  margin: 10px 0 40px 0;
}

.filters-bar .filter-item {
  color: #999;
  border-left: solid 1px #eee;
  padding: 0 10px;
}

.filters-bar .filter-item input[type=date] {
  border: 0;
  color: #999;
}

projects-list md-list {
  padding: 0;
}

projects-list md-list md-list-item {
  border-bottom: solid 1px #ddd;
}

projects-list md-list md-list-item .small-input-column {
  margin-left: 25px;
}

projects-list md-list md-list-item .small-input-column small {
  line-height: 20px;
}

projects-list md-list md-list-item .small-input-column div {
  line-height: 22px;
}

projects-list md-list md-list-item .progress {
  min-width: 70px;
  text-align: right;
}

projects-list md-list md-list-item .progress md-icon {
  margin-right: 0;
  padding-right: 0;
  margin-left: 5px;
  opacity: 0.7;
}

statistics-list .filters-bar {
  margin: 10px 0 40px 0;
}

statistics-list md-list {
  padding: 0;
}

statistics-list md-list md-list-item {
  border-bottom: solid 1px #ddd;
}

statistics-list md-list md-list-item .small-input-column {
  margin-left: 25px;
}

statistics-list md-list md-list-item .small-input-column small {
  line-height: 20px;
}

statistics-list md-list md-list-item .small-input-column div {
  line-height: 22px;
}

team-working-status {
  color: #aaa;
}

team-working-status .avatar-status {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

team-working-status .user-name {
  font-weight: bold;
}

team-working-status .working .avatar-status {
  border: solid 3px #758f10;
}

team-working-status .paused .avatar-status {
  border: solid 3px #ff6400;
}

team-working-status .stopped .avatar-status {
  border: solid 3px #ccc;
  opacity: 0.3;
}

team-working-status .active.working .avatar-status {
  border-color: #cbeb4d;
}

project-details .title-input {
  width: 100%;
  margin-top: 20px;
}

project-details .no-tasks-warning {
  opacity: 0.5;
}

project-details .no-tasks-warning h1 {
  font-size: 60px;
  opacity: 0.6;
  font-weight: 300;
}

project-details .no-tasks-warning h3 {
  font-size: 35px;
  text-transform: uppercase;
  opacity: 0.7;
}

project-details .no-tasks-warning md-icon {
  width: auto;
  height: auto;
  font-size: 70px;
  opacity: 0.6;
}

project-stories .no-stories-warning {
  opacity: 0.5;
}

project-stories .no-stories-warning h1 {
  font-size: 60px;
  opacity: 0.6;
  font-weight: 300;
}

project-stories .no-stories-warning h3 {
  font-size: 35px;
  text-transform: uppercase;
  opacity: 0.7;
}

project-stories .no-stories-warning md-icon {
  width: auto;
  height: auto;
  font-size: 70px;
  opacity: 0.6;
}

project-stories .main-item .md-list-item-inner {
  padding: 16px 0 16px 16px;
}

md-list-item:before {
  display: none;
}

.tasks-list md-list-item {
  margin-right: 0;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
}

task-preview, task-headline {
  border-bottom: solid 1px #ececec;
  outline: none !important;
}

task-preview > div, task-headline > div {
  min-height: 50px;
  height: 100%;
  margin: 0 !important;
}

task-preview > div .md-button, task-headline > div .md-button {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

task-preview .task-headline, task-headline .task-headline {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
}

task-preview .open, task-headline .open {
  background: white;
  box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.15);
}

task-preview .open .task-headline, task-headline .open .task-headline {
  background: #eee;
  margin: 0;
}

task-preview .open .deliverables-list, task-headline .open .deliverables-list {
  padding-left: 0;
  margin: 0;
}

task-preview .open .deliverables-list li, task-headline .open .deliverables-list li {
  list-style: none;
  font-size: 14px;
}

task-preview .open .deliverables-list li .md-button, task-headline .open .deliverables-list li .md-button {
  height: auto;
  visibility: hidden;
  opacity: 0.6;
  padding: 0;
  width: 30px;
}

task-preview .open .deliverables-list li .md-button md-icon, task-headline .open .deliverables-list li .md-button md-icon {
  margin-right: 0;
}

task-preview .open .deliverables-list li .md-button:hover, task-headline .open .deliverables-list li .md-button:hover {
  opacity: 1;
}

task-preview .open .deliverables-list li:hover .md-button, task-headline .open .deliverables-list li:hover .md-button {
  visibility: visible;
}

task-preview .open .comments-list, task-headline .open .comments-list {
  padding-left: 0;
  margin: 0;
}

task-preview .open .comments-list li, task-headline .open .comments-list li {
  list-style: none;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

task-preview .open .comments-list li .md-button, task-headline .open .comments-list li .md-button {
  height: auto;
  visibility: hidden;
  opacity: 0.6;
  padding: 0;
  width: 30px;
}

task-preview .open .comments-list li .md-button md-icon, task-headline .open .comments-list li .md-button md-icon {
  margin-right: 0;
}

task-preview .open .comments-list li .md-button:hover, task-headline .open .comments-list li .md-button:hover {
  opacity: 1;
}

task-preview .open .comments-list li:hover .md-button, task-headline .open .comments-list li:hover .md-button {
  visibility: visible;
}

task-preview .open .comments-list li .user-comments h1, task-headline .open .comments-list li .user-comments h1 {
  display: none;
}

task-preview .open .comments-list li .user-comments img, task-headline .open .comments-list li .user-comments img {
  max-width: 40px;
  max-height: 40px;
}

task-preview .open .task-details-executions, task-headline .open .task-details-executions {
  display: block;
  margin: 50px auto;
}

task-preview .open .task-details-executions .project-select, task-headline .open .task-details-executions .project-select {
  display: none;
}

task-preview .open .task-details-executions .task-name, task-headline .open .task-details-executions .task-name {
  display: none;
}

task-preview .open .task-details-executions .task-headline, task-headline .open .task-details-executions .task-headline {
  display: none;
}

task-preview .open .task-details-executions md-list, task-headline .open .task-details-executions md-list {
  padding: 0;
}

task-preview .open .task-details-executions md-list md-list-item, task-headline .open .task-details-executions md-list md-list-item {
  border-bottom: 10px;
  border-top: 10px;
}

task-preview .open .task-details-executions md-list md-list-item div, task-headline .open .task-details-executions md-list md-list-item div {
  line-height: 0px;
}

task-preview .open .task-details-executions .open-new-window .md-icon-button, task-headline .open .task-details-executions .open-new-window .md-icon-button {
  display: none;
}

task-preview .open form, task-headline .open form {
  padding-left: 30px;
}

task-preview .open form md-input-container .md-errors-spacer, task-headline .open form md-input-container .md-errors-spacer {
  display: none;
}

task-preview .open .task-executions, task-headline .open .task-executions {
  border-left: solid 1px #efefef;
  margin-bottom: -5px;
}

task-preview .open .bottom-options .md-button, task-headline .open .bottom-options .md-button {
  font-size: 12px;
  margin-bottom: 0;
  padding-right: 15px;
}

task-preview .open .bottom-options .md-button md-icon, task-headline .open .bottom-options .md-button md-icon {
  font-size: 18px;
  padding-top: 3px;
}

task-preview .closed, task-headline .closed {
  opacity: 0.5;
}

task-preview .closed .task-name, task-headline .closed .task-name {
  text-decoration: line-through;
}

task-preview .task-name, task-headline .task-name {
  padding: 10px;
  margin-left: 0px;
  outline: none;
  border-bottom: solid 2px transparent;
  opacity: 0.8;
  min-width: 200px;
}

task-preview .task-name:focus, task-headline .task-name:focus {
  border-color: #222;
}

task-preview md-input-container .md-select-value, task-headline md-input-container .md-select-value {
  border-bottom: 0;
}

task-preview md-input-container .md-select-value .md-select-icon, task-headline md-input-container .md-select-value .md-select-icon {
  display: none;
}

task-preview md-input-container.md-input-has-value label, task-headline md-input-container.md-input-has-value label {
  display: none;
}

task-preview md-input-container md-icon, task-headline md-input-container md-icon {
  opacity: 0.7;
  font-size: 16px !important;
  padding-top: 5px;
  padding-right: 0;
  width: auto;
}

md-option.hide-icons md-icon {
  display: none !important;
}

task-preview .task-user-select, task-headline .task-user-select {
  width: 50px;
}

user-badge.task-user-select-user-badge h1 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

user-badge.task-user-select-user-badge img {
  max-width: 30px;
  max-height: 30px;
}

task-preview .task-user-select .md-select-value h1, task-headline .task-user-select .md-select-value h1 {
  display: none;
}

task-preview .task-user-select .md-select-value img, task-headline .task-user-select .md-select-value img {
  margin-left: 5px;
}

task-preview .start-working-btn, task-headline .start-working-btn {
  visibility: hidden;
  padding-left: 0;
}

task-preview .stop-working-dial, task-headline .stop-working-dial {
  margin-left: 0;
  margin-right: -10px;
  position: relative;
}

task-preview .stop-working-dial:hover .stop-working-btn, task-headline .stop-working-dial:hover .stop-working-btn {
  background: #222 !important;
}

task-preview .stop-working-dial:hover .stop-working-btn md-icon, task-headline .stop-working-dial:hover .stop-working-btn md-icon {
  color: white;
}

task-preview .stop-working-dial md-fab-actions, task-headline .stop-working-dial md-fab-actions {
  position: absolute;
  top: 90%;
  padding-left: 40px;
}

task-preview .stop-working-dial md-fab-actions .md-fab, task-headline .stop-working-dial md-fab-actions .md-fab {
  position: relative;
  padding-left: 8px;
  left: -20px;
}

task-preview .working-time, task-headline .working-time {
  opacity: 0.6;
  margin-left: 5px;
}

task-preview .working-time > *[ng-click], task-headline .working-time > *[ng-click] {
  outline: none;
}

task-preview:hover .working-btn, task-headline:hover .working-btn {
  visibility: visible;
}

.small-input-column > small {
  font-size: 12px;
  text-transform: uppercase;
}

.small-input-column input {
  opacity: 0.7;
}

.small-input-column div {
  font-size: 16px;
  opacity: 0.5;
}

.filters-bar {
  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  padding: 0 10px;
}

.filters-bar input[type=text] {
  border: 0;
  height: 100%;
  padding: 0 10px;
  min-height: 50px;
  outline: none;
}

.filters-bar > * {
  margin: 0 5px;
}

.user-pages-menu {
  color: inherit !important;
  opacity: 0.7;
  letter-spacing: 1px;
  border-bottom: solid 2px transparent;
  font-size: 20px;
  font-weight: normal;
}

.user-pages-menu.active {
  color: #222 !important;
  border-color: #222;
  background-color: rgba(158, 158, 158, 0.3);
}

.user-pages-menu:not(.active):not(:hover) {
  opacity: 0.3;
}

.project-select {
  min-width: 130px;
  margin: 0;
  padding: 0;
}

.project-select label {
  display: none !important;
}

.project-select md-select-value {
  font-size: 14px;
  padding-top: 0;
  text-align: center;
  border-radius: 30px;
  background: rgba(34, 34, 34, 0.1);
}

.project-select md-select-value .md-select-icon {
  display: none;
}

.project-select md-icon {
  padding-top: 3px;
  color: inherit;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.task-project-select-search {
  padding: 15px 17px;
  outline: none;
  border: 0;
  border-bottom: solid 1px #eee;
}

.new-task-fake-input {
  background: white;
  border: solid 1px #ccc;
}

.new-task-fake-input input {
  background: transparent;
  border: 0;
  outline: none;
  padding: 0 15px;
  min-height: 55px;
}

.new-task-fake-input md-input-container {
  margin-right: 15px;
}

.new-task-fake-input .avatar-sm {
  max-width: 30px;
  max-height: 30px;
}

reports-view {
  overflow-y: auto;
  height: 100%;
}

reports-view h2 {
  margin-bottom: 0;
}

reports-view h2 .md-button {
  visibility: hidden;
}

reports-view .week-view:hover h2 .md-button {
  visibility: visible;
}

reports-view .week-view {
  margin-bottom: 30px;
}

reports-view user-badge img {
  max-width: 30px !important;
  max-height: 30px !important;
}

reports-view user-badge h1 {
  font-size: 20px;
  margin: 0;
  margin-left: 15px;
}

.fake-input {
  background: transparent;
  border: 0;
  outline: none;
  padding: 0 15px;
}

.fake-input:focus {
  border-bottom: solid 2px #222;
}

project-statistic user-badge img {
  max-width: 30px !important;
}

project-statistic user-badge h1 {
  font-size: 16px;
}

user-statistic .working-project {
  margin-left: 30px;
}

user-statistic .working-project h1 {
  font-size: 16px;
}

user-statistic user-badge img {
  max-width: 40px !important;
}

user-statistic user-badge h1 {
  font-size: 19px;
}

user-badge h1 {
  margin-left: 10px;
}

[contenteditable].single-line {
  white-space: nowrap;
}

[contenteditable].single-line br {
  display: none;
}

[contenteditable].single-line * {
  display: inline;
  white-space: nowrap;
}

h1, h2, h3 {
  font-weight: 300;
}

.avatar {
  max-width: 60px;
  max-height: 60px;
  border-radius: 600px;
}

.avatar-sm {
  max-width: 40px;
  max-height: 40px;
  border-radius: 40px;
}

.avatar-xs {
  max-width: 30px;
  max-height: 30px;
  border-radius: 30px;
}

a, a:active, a:visited {
  color: #758f10;
  text-decoration: none;
}

.padding {
  padding: 20px;
}

.padding > md-card {
  margin-left: 0;
  margin-right: 0;
}

#loading-bar .bar {
  background: #758f10;
}

#loading-bar-spinner {
  left: 41px;
  top: 49px;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: white !important;
  border-left-color: white !important;
  color: white !important;
}

.title-input {
  font-size: 32px;
  background: transparent;
  border: 0;
  border-bottom: solid 2px transparent;
  outline: none;
}

.title-input:hover {
  border-bottom: solid 2px #ccc;
}

.title-input:focus {
  border-bottom: solid 2px #222;
}

.transition-300, .user-pages-menu, .title-input, #change-avatar-area .change-avatar-btn {
  transition: all 300ms ease !important;
}

.transition-1000 {
  transition: all 1s ease;
}

.text-accent {
  color: #758f10;
}

.text-warning {
  color: #ff6400;
}

.text-success {
  color: #00e600;
}

.text-danger {
  color: #ff6666;
}

.text-fade, .small-input-column > small {
  opacity: 0.5;
}

.text-center {
  text-align: center;
}

.no-bg {
  background: transparent;
}

.no-margin {
  margin: 0;
}

.bg-cover, #change-avatar-area {
  background-size: cover;
  background-position: center center;
}

.bg-danger {
  background: rgba(255, 0, 0, 0.1);
}

.bg-success {
  background: rgba(0, 128, 0, 0.1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

#change-avatar-area {
  min-width: 250px;
  min-height: 250px;
  max-width: 250px;
  max-height: 250px;
  border-radius: 250px;
  position: relative;
  margin-bottom: 50px;
}

#change-avatar-area .change-avatar-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 250px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  cursor: pointer;
}

#change-avatar-area:hover .change-avatar-btn {
  opacity: 1;
}

project-stories .grey {
  color: grey;
}

project-stories .red {
  color: red;
}

project-stories .blue {
  color: blue;
}

project-stories h4 {
  margin: 10px 0;
}

project-stories .sort-by {
  margin: 0 3px;
}

project-stories .disguised {
  background: transparent;
  border: 0;
  outline: none;
  transition: width 0.20s;
  text-align: justify;
}

project-stories .baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

project-stories .right {
  margin-right: 5px;
}

project-stories .left {
  margin-left: 10px;
}

project-stories .small-button .md-button.md-icon-button {
  margin: 0px;
  padding: 0px;
  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;
}

project-stories .small-icon {
  font-size: 16px;
  min-height: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
  width: 16px !important;
}

project-stories .no-border {
  border: 0;
}

project-stories .light-border {
  border: 1px solid #eeeeee;
}

project-stories .normal {
  background-color: transparent;
}

project-stories .on-hover {
  background-color: #f5f5f5;
}

project-stories .no-padding md-list-item .md-no-style {
  padding: 0 !important;
  margin: 0px;
}

project-stories .disable-md-clickable .md-ripple-container {
  display: none;
}

.new-role-select {
  padding: 15px 17px;
  outline: none;
  border: 0;
}

project-stories input[type=number] {
  outline: none;
  border: none;
  background-color: transparent;
  width: 35px;
  text-align: center;
  padding: 0px !important;
  min-height: 20px !important;
}

project-stories input[type=number]::-webkit-outer-spin-button, project-stories input[type=number]::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: inner-spin-button;
  opacity: 1 !important;
  margin: 1px;
}

project-stories .role-complete {
  min-width: 90px;
  width: 90px;
  margin-left: 15px;
}

project-stories .role-complete md-autocomplete-wrap {
  box-shadow: none;
}

project-stories .role-complete md-autocomplete-wrap input {
  height: auto;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  padding: 0;
}

project-stories .role-complete md-input-container {
  margin: 0;
}

project-stories .role-complete md-input-container .md-errors-spacer {
  display: none;
}

.delivery {
  padding: 2px 5px;
  height: 20px;
  margin-left: 20px;
}

.delivery:focus {
  outline: 0;
}

.delivery input[type=number] {
  outline: none;
  border: none;
  background-color: transparent;
  width: 35px;
  text-align: center;
  padding: 0px !important;
  min-height: 20px !important;
}

.delivery input[type=number]::-webkit-outer-spin-button, .delivery input[type=number]::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: inner-spin-button;
  opacity: 1 !important;
  margin: 1px;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  width: 130px;
}

.smaller-text {
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
}

.editable-wrap {
  width: 100% !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.proposal-preview {
  border: 1px solid #cccccc;
  padding: 40px;
  text-align: justify;
}

.proposal-preview ul {
  margin: 0;
}

.proposal-preview .proposal-title {
  font-size: 22px;
  font-weight: 600;
}

.proposal-preview .proposal-section .section-title {
  margin: 5px 0;
}

.proposal-preview .proposal-section .section-content {
  margin: 15px 0;
}

.proposal-preview .proposal-section .sub-section-content {
  margin: 10px 0;
}

.proposal-preview .proposal-subtitle {
  font-size: 17px;
  font-weight: 500;
}

.proposal-preview .proposal-sub-subtitle {
  font-size: 14px;
  font-weight: 500;
}

.proposal-preview .proposal-text {
  font-size: 13px;
}

@media print {
  .hide-on-print {
    display: none;
  }
  .proposal-preview {
    border: none;
    padding: 10px;
  }
}

.tab-btn {
  color: #6A6A6A !important;
  border-bottom: solid 2px rgba(106, 106, 106, 0.3);
}

.tab-btn.active {
  color: #758f10 !important;
  border-color: #758f10;
}

